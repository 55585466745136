<template>
  <arora-nuxt-link
    class="v-banner-info"
    :href="
      actionBanner.UseLinkOnBanner ? actionBanner.LinkOnBanner : actionBanner.Link
    "
    :label="actionBanner.Title"
  >
    <arora-nuxt-image
      disable-lazy
      :alt="actionBanner.Title"
      :image="actionBanner.ImageSmallEventInfo"
    />
    <div
      v-if="!actionBanner.NotShowTextOnBanner"
      class="v-description-action-block"
    >
      <div
        class="v-title"
        v-html="sanitize(actionBanner.Title)"
      />
      <div
        class="v-teaser"
        v-html="sanitize(actionBanner.Teaser)"
      />
    </div>
  </arora-nuxt-link>
</template>

<script setup lang="ts">
import type { Action } from '~types/pageStore'

defineProps<{ actionBanner: Action }>()
const { sanitize } = useI18nSanitized()
</script>
