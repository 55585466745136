<i18n>
ru:
  moreDetails: Попробовать
ua:
  moreDetails: Спробуй це
us:
  moreDetails: Try it
</i18n>

<template>
  <home-banners-minas-ithil
    v-if="appConfig.VueSettingsPreRun.ActionsBannersMainLayout === 'MinasIthil'"
  />
  <home-banners-minas-anor
    v-else-if="appConfig.VueSettingsPreRun.ActionsBannersMainLayout === 'MinasAnor'"
  />
  <home-banners-minas-tirith
    v-else-if="
      appConfig.VueSettingsPreRun.ActionsBannersMainLayout === 'MinasTirith'
    "
  />
  <home-banners-minas-morgul
    v-else-if="
      appConfig.VueSettingsPreRun.ActionsBannersMainLayout === 'MinasMorgul'
    "
  />
</template>

<script setup lang="ts">
const pageStore = usePageStore()
const appConfig = useAppConfig()

onMounted(() => {
  pageStore.initActions()
})
</script>
