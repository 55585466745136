<template>
  <section
    v-if="pageStore.ActionsOnMain"
    id="v-banners-main-page__Minas-Ithil"
  >
    <div class="v-container">
      <div
        v-if="pageStore.Actions.state === 'success'"
        class="v-row"
      >
        <div class="v-col-12">
          <arora-swiper-slider
            v-if="isDesktopVersion"
            class-name="v-banner-main-page-item__Minas-Ithil"
            :autoplay-delay="
              appConfig.VueSettingsPreRun.ActionsBannersDelaySec * 1000
            "
            centered
            :items="pageStore.ActionsOnMain"
            :loop="pageStore.ActionsOnMain.length > 2"
            max-items="auto"
            pagination-type="dynamic-bullets"
            :space-between="0"
          >
            <template #item="item: Action">
              <arora-nuxt-link
                v-if="item.NotShowTextOnBanner"
                class="v-banner-info"
                :href="item.UseLinkOnBanner ? item.LinkOnBanner : item.Link"
                :label="item.Title"
              >
                <arora-nuxt-image
                  disable-lazy
                  :alt="item.Title"
                  :image="item.ImageBigBannerInfo"
                  image-type="EventBannerBig"
                />
              </arora-nuxt-link>
              <div v-else>
                <arora-nuxt-image
                  disable-lazy
                  :alt="item.Title"
                  :image="item.ImageBigBannerInfo"
                  image-type="EventBannerBig"
                />
                <div
                  v-if="!item.NotShowTextOnBanner"
                  class="v-description-action-block v-mobile-action"
                >
                  <div
                    class="v-title"
                    v-html="sanitize(item.Title)"
                  />
                  <div
                    class="v-teaser"
                    v-html="sanitize(item.Teaser)"
                  />
                  <arora-nuxt-link
                    class="v-btn v-btn-lg v-btn-banner-info"
                    :href="item.UseLinkOnBanner ? item.LinkOnBanner : item.Link"
                    :label="translate('bannersPage.moreDetails')"
                  />
                </div>
              </div>
            </template>
          </arora-swiper-slider>
          <arora-carousel
            v-else
            :items="pageStore.ActionsOnMain"
            :loop="pageStore.ActionsOnMain.length > 2"
            :max-items="7.15"
            :min-items="1.15"
            show-part-of-next-slide
            :autoplay-delay="
              appConfig.VueSettingsPreRun.ActionsBannersDelaySec * 1000
            "
            pagination-type="none"
            :space-between="15"
            snap-align="start"
          >
            <template #item="item: Action">
              <div class="v-banner-info">
                <arora-nuxt-link
                  class="v-w-100 v-h-100"
                  :href="item.UseLinkOnBanner ? item.LinkOnBanner : item.Link"
                  :label="item.Title"
                >
                  <arora-nuxt-image
                    disable-lazy
                    :alt="item.Title"
                    :image="item.ImageSmallEventInfo"
                    image-type="EventSmall"
                  />
                  <div
                    v-if="!item.NotShowTextOnBanner"
                    class="v-description-action-block v-mobile-action"
                  >
                    <div
                      class="v-title"
                      v-html="sanitize(item.Title)"
                    />
                    <div
                      class="v-teaser"
                      v-html="sanitize(item.Teaser)"
                    />
                  </div>
                </arora-nuxt-link>
              </div>
            </template>
          </arora-carousel>
        </div>
      </div>
      <common-skeleton
        v-else
        :height="175"
        :loaders-in-row="5"
        :space-between="20"
        :width="175"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import type { Action } from '~types/pageStore'

import { useWindowSize } from '@arora/common'

const appConfig = useAppConfig()
const { sanitize, translate } = useI18nSanitized()

const { isDesktopVersion } = useWindowSize()

const pageStore = usePageStore()
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

#v-banners-main-page__Minas-Ithil {
  .swiper-slide {
    position: relative;
    width: 100%;
    transform: scale(0.8);
    @include mixins.trans;
    @include mixins.md {
      width: calc(40%);
      transform: scale(1);
    }

    img {
      width: 100%;
      overflow: hidden;
      border-radius: variables.$BorderRadius;
      box-shadow: variables.$CardShadow;
    }
    &.swiper-slide-active {
      transform: scale(1);
    }
  }

  .swiper-pagination-bullets {
    bottom: -12px;
    .swiper-pagination-bullet-active {
      background: variables.$SecondaryBackgroundColor;
    }
  }

  .v-banner-info {
    box-shadow: variables.$BannerShadow;
    border-radius: variables.$BorderRadius;
    position: relative;
    display: flex;
    @include mixins.lg {
      border: 2px solid variables.$SecondaryBackgroundColor;
      padding: 6px;
      display: flex;
    }
  }
  .v-description-action-block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    color: #fff;
    .v-title {
      position: relative;
      z-index: 2;
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 8px;
      p {
        margin: 0;
      }
    }
    .v-teaser {
      position: relative;
      z-index: 2;
      font-size: 1.7rem;
      margin-bottom: 35px;
      p {
        margin: 0;
      }
    }
    .v-btn-banner-info {
      border-radius: variables.$BorderRadius;
      color: #250000;
      background: rgba(255, 255, 255, 0.7);
      font-size: 1.2rem;
      font-weight: 500;
    }
    @include mixins.lg {
      &.v-mobile-action {
        top: initial;
        bottom: 6px;
        left: 6px;
        right: 6px;
        transform: initial;
        color: #222;
        padding: 0;
        align-items: start;
        padding: 4px;
        .v-title {
          font-size: 1rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .v-teaser {
          font-size: 0.8rem;
          margin-bottom: 1.75rem;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          background: rgba(255, 255, 255, 0.6);
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }
    }
  }
}
</style>
