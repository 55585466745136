<template>
  <section
    v-if="pageStore.ActionsOnMain"
    id="v-banners-main-page__Minas-Morgul"
  >
    <div class="v-container">
      <div
        v-if="pageStore.Actions.state === 'success'"
        class="v-row"
      >
        <div class="v-col-12">
          <arora-carousel
            v-if="isSmall"
            :items="pageStore.ActionsOnMain"
            :loop="pageStore.ActionsOnMain.length > 2"
            :max-items="5"
            :min-items="2.1"
            show-part-of-next-slide
            :autoplay-delay="
              pageStore.ActionsOnMain.length > 2
                ? appConfig.VueSettingsPreRun.ActionsBannersDelaySec * 1000
                : 0
            "
            pagination-type="none"
            :space-between="20"
            snap-align="start"
          >
            <template #item="item">
              <home-banners-minas-morgul-single :action-banner="item" />
            </template>
          </arora-carousel>
          <arora-carousel
            v-else
            :items="pageStore.ActionsOnMain"
            :loop="pageStore.ActionsOnMain.length > 5"
            :max-items="5"
            :min-items="1"
            show-part-of-next-slide
            :autoplay-delay="
              pageStore.ActionsOnMain.length > 5
                ? appConfig.VueSettingsPreRun.ActionsBannersDelaySec * 1000
                : 0
            "
            :pagination-type="
              pageStore.ActionsOnMain.length > 5 ? 'dynamic-bullets' : 'none'
            "
            :space-between="20"
            snap-align="start"
          >
            <template #item="item">
              <home-banners-minas-morgul-single :action-banner="item" />
            </template>
          </arora-carousel>
        </div>
      </div>
      <common-skeleton
        v-else
        :height="250"
        :loaders-in-row="5"
        :space-between="20"
        :width="250"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useWindowSize } from '@arora/common'

const { isSmall } = useWindowSize()
const appConfig = useAppConfig()
const pageStore = usePageStore()
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

#v-banners-main-page__Minas-Morgul {
  margin-bottom: 60px;
  @include mixins.sm {
    margin-bottom: 20px;
  }
  .summary-wrapper {
    padding: 0;
  }

  .v-banner-info {
    filter: variables.$BannerShadow;
    border-radius: variables.$BorderRadius;
    position: relative;
    border: 2px solid variables.$SecondaryBackgroundColor;
    padding: 6px;
    display: flex;

    min-width: 100%;

    img {
      width: 100%;
      border-radius: max(4px, calc(variables.$BorderRadius - 6px));
    }

    .v-description-action-block {
      position: absolute;
      bottom: 6px;
      left: 6px;
      right: 6px;
      color: #000015;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.6);
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      .v-title {
        position: relative;
        z-index: 2;
        padding: 12px 12px 0 12px;
        font-size: 1rem;
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .v-teaser {
        position: relative;
        z-index: 2;
        padding: 0 12px 12px;
        font-size: 0.8rem;
        opacity: 0.8;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
</style>
